import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  useMediaQuery,
  TextField,
} from "@mui/material";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import AddIcon from "@mui/icons-material/Add";
import "./styles/Enquiry.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Output } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-toastify/dist/ReactToastify.css";

const Enquiry = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState("");
  const [page, setPage] = useState(1);
  const [download, setdownload] = useState("");
  const [filterDate, setFilterDate] = useState(""); // New state for date filter

  const isDesktop = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    EnquiryList();
  }, [filterDate]); // Re-fetch when filterDate changes

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const EnquiryList = (page = 1) => {
    const formattedDate = filterDate;
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/list/form",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
      data: {
        limit: 10,
        page_no: page,
        date: formattedDate,
        // format: "pdf",
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          let listData = data.data.list ? data.data.list : [];
          let Listing = listData?.map((eachteam, index) => {
            return {
              ...eachteam,
              f_id: eachteam.f_id || "",
              f_name: eachteam.f_name || "",
              f_phno: eachteam.f_phno || "",
              f_email: eachteam.f_email || "",
              f_job: eachteam.f_job || "",
              f_country: eachteam.f_country || "",
              f_nearest_office: eachteam.f_nearest_office || "",
              f_work_plan: eachteam.f_work_plan || "",
              f_counselling_type: eachteam.f_counselling_type || "",
              f_work_level: eachteam.f_work_level || "",
              f_status: eachteam.f_status || "",
              f_created_at: eachteam.f_created_at || "",
            };
          });
          if (page === 1) {
            setEnquiries(data.data.list);
            setLoading(false);
          } else {
            const temp = [...enquiries];
            setEnquiries([...temp, ...Listing]);
          }
        } else {
          // setEnquiries([]);
          // toast.warning("No list Found");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };
  const PdfDownload = () => {
    const formattedDate = filterDate;
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/list/form",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
      data: {
        date: formattedDate,
        format: "pdf",
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          setdownload(data?.data?.filepath);
          onButtonClick();
        } else {
          console.log("not working");
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };
  console.log(download, "download");

  const onButtonClick = async () => {
    try {
      const response = await axios.get(download, {
        responseType: "blob",
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(pdfBlob);

      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `globalinternational.pdf`);
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    // fetch(download).then((response) => {
    //   response.blob().then((blob) => {
    //     const fileURL = window.URL.createObjectURL(blob);
    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = download;
    //     alink.click();
    //   });
    // });
  };
  const onPageLoad = () => {
    setPage(page + 1);
    EnquiryList(page + 1);
  };

  const handleDateChange = (event) => {
    setEnquiries([]);
    setFilterDate(event.target.value); // Update the date filter
  };

  const topbarHeight = 64;

  return (
    <>
      <ToastContainer autoClose={3000} theme={"light"} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          paddingRight: "20px",
        }}
      >
        <Box
          sx={{
            height: `${topbarHeight}px`,
            width: "100%",
            position: "fixed",
            top: 0,
            zIndex: 100,
          }}
        >
          <Topbar toggleSidebar={handleDrawerToggle} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            marginTop: `${topbarHeight}px`,
          }}
        >
          <Sidebar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              overflowY: "auto",
              backgroundColor: "white",
              transition: "margin-left 0.3s ease",
              marginLeft: isSidebarOpen && isDesktop ? "240px" : "0px",
            }}
          >
            <Box className="enquiry-table-container">
              <Box className="table-header">
                <TextField
                  id="date"
                  label="Filter by Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterDate}
                  onChange={handleDateChange}
                  sx={{ marginRight: "16px" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Output />}
                  className="add-enquiry-button"
                  onClick={PdfDownload}
                >
                  Export as Pdf
                </Button>
              </Box>
              <InfiniteScroll
                style={{
                  overflow: "hidden",
                }}
                dataLength={enquiries?.length}
                next={onPageLoad}
                hasMore={total !== false}
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    <b> No More List</b>
                  </p>
                }
              >
                <TableContainer component={Paper}>
                  <Table aria-label="Enquiry Table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>mobile no</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Go To Country</TableCell>
                        <TableCell>Counselling Type</TableCell>
                        <TableCell>Nearest Office</TableCell>
                        <TableCell>Work Type</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {enquiries.length <= 0 ? (
                        <TableRow
                          style={{
                            // backgroundColor: "red",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <>No List Found</>
                        </TableRow>
                      ) : (
                        enquiries?.map((enquiry, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{enquiry.f_name}</TableCell>
                            <TableCell>{enquiry.f_phno}</TableCell>
                            <TableCell>{enquiry.f_email}</TableCell>
                            <TableCell>{enquiry.f_country}</TableCell>
                            <TableCell>{enquiry.f_counselling_type}</TableCell>
                            <TableCell>{enquiry.f_nearest_office}</TableCell>
                            <TableCell>{enquiry.f_work_level}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfiniteScroll>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Enquiry;
