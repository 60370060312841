import React, { useState } from "react";
import { Button, TextField, Paper, Typography, Box } from "@mui/material";
import BackgroundImage from "../assets/globle.jpg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [resetRequested, setResetRequested] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = () => {
    if (email) {
      let requestBody = {
        email: email,
      };
      axios({
        url: "https://lunarsenterprises.com:6004/workabroad/forgotpassword",
        method: "post",
        data: requestBody,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((data) => {
        if (data.data.status === true) {
          setResetRequested(true);
          toast.success("OTP has been sent to your email");
        } else {
          toast.error("Error sending OTP. Please try again.");
        }
      });
    } else {
      alert("Please enter your email");
    }
  };

  const handleVerifyOtp = () => {
    if (otp) {
      let otpBody = {
        email: email,
        code: otp,
      };
      axios({
        url: "https://lunarsenterprises.com:6004/workabroad/emailverification",
        method: "post",
        data: otpBody,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((data) => {
        if (data.data.result === true) {
          setOtpVerified(true);
          toast.success("OTP verified successfully");
        }
      });
    } else {
      toast.error("Please enter the OTP");
    }
  };

  const handlePasswordChange = () => {
    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        let otpBody = {
          email: email,
          password: confirmPassword,
        };
        axios({
          url: "https://lunarsenterprises.com:6004/workabroad/changepassword",
          method: "post",
          data: otpBody,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then((data) => {
          if (data.data.result === true) {
            history.push("/");
            toast.success("Password Changed Successfully");
          } else {
            toast.error("Invalid OTP. Please try again.");
          }
        });
      } else {
        toast.error("Passwords do not match");
      }
    } else {
      toast.error("Please fill in both password fields");
    }
  };

  return (
    <>
      {/* <ToastContainer autoClose={3000} theme={"light"} /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Paper
          sx={{ p: 4, maxWidth: 400, width: "100%", backgroundColor: "white" }}
        >
          {!resetRequested ? (
            <>
              {/* Step 1: Email Input */}
              <Typography variant="h5" component="h1" gutterBottom>
                Forgot Password
              </Typography>
              <Typography variant="body2" gutterBottom>
                Enter your email address to receive an OTP.
              </Typography>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleResetPassword}
              >
                Send OTP
              </Button>
            </>
          ) : !otpVerified ? (
            <>
              {/* Step 2: OTP Verification */}
              <Typography variant="h5" component="h1" gutterBottom>
                Verify OTP
              </Typography>
              <Typography variant="body2" gutterBottom>
                Enter the OTP sent to your email.
              </Typography>
              <TextField
                label="OTP"
                variant="outlined"
                fullWidth
                margin="normal"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleVerifyOtp}
              >
                Verify OTP
              </Button>
            </>
          ) : (
            <>
              {/* Step 3: Change Password */}
              <Typography variant="h5" component="h1" gutterBottom>
                Reset Your Password
              </Typography>
              <Typography variant="body2" gutterBottom>
                Please enter your new password below.
              </Typography>
              <TextField
                label="New Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                label="Confirm Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handlePasswordChange}
              >
                Change Password
              </Button>
            </>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default ForgotPassword;
