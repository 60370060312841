import React, { useState } from "react";
import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import { Link, Route, Switch, Redirect, useLocation } from "react-router-dom";
import WorkDestination from "./WorkDestination";
import PartneringCompanies from "./PartneringCompanies";
import Testimonials from "./Testimonials";
import AddPages from "../AddPages";
import TestimonialAdd from "../TestimonialAdd";
import PartneringAdd from "../PartneringAdd";
import EditPages from "../EditPages";
import TestimonialEdit from "../TestimonialEdit";
import Topbar from "../Topbar";
import Sidebar from "../Sidebar";

const Dashboard = () => {
  const location = useLocation();
  const currentTab = location.pathname;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isDesktop = useMediaQuery("(min-width:600px)");
  const topbarHeight = 64;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Box
          sx={{
            height: `${topbarHeight}px`,
            width: "100%",
            position: "fixed",
            top: 0,
            zIndex: 100,
          }}
        >
          <Topbar toggleSidebar={handleDrawerToggle} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            marginTop: `${topbarHeight}px`,
          }}
        >
          <Sidebar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Box
            sx={{
              flexGrow: 1,
              padding: 2,
              overflowY: "auto",
              backgroundColor: "#f5f5f5",
              transition: "margin-left 0.3s ease",
              marginLeft: isSidebarOpen && isDesktop ? "240px" : "0px",
            }}
          >
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              centered
            >
              <Tab
                label="Work Destination"
                value="/services/WorkDestination"
                to="/services/WorkDestination"
                component={Link}
              />
              <Tab
                label="Testimonials"
                value="/services/Testimonials"
                to="/services/Testimonials"
                component={Link}
              />
              {/* <Tab
          label="Partnering Companies"
          value="/dashboard/PartneringCompanies"
          to="/dashboard/PartneringCompanies"
          component={Link}
        /> */}
            </Tabs>

            <Box sx={{ padding: 2 }}>
              <Switch>
                {/* Redirect from /dashboard to /dashboard/WorkDestination */}
                <Route exact path="/services">
                  <Redirect to="/services/WorkDestination" />
                </Route>

                <Route
                  path="/services/WorkDestination"
                  component={WorkDestination}
                />
                <Route path="/services/Testimonials" component={Testimonials} />
                <Route
                  path="/services/PartneringCompanies"
                  component={PartneringCompanies}
                />
                <Route path="/services/Addpages" component={AddPages} />
                <Route path="/services/EditPages" component={EditPages} />
                <Route
                  path="/services/TestimonialAdd"
                  component={TestimonialAdd}
                />
                <Route
                  path="/services/TestimonialEdit"
                  component={TestimonialEdit}
                />
                <Route
                  path="/services/PartneringAdd"
                  component={PartneringAdd}
                />
              </Switch>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
