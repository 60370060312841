import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const EditPages = () => {
  const location = useLocation();
  const { workLocationId } = location.state || {};

  const [formData, setFormData] = useState({
    s_place: "",
    s_image: null,
    sd_description1: "",
    sd_description2: "",
  });
  const [Details, setDetails] = useState({
    s_place: "",
    s_image: null,
    sd_description1: "",
    sd_description2: "",
  });
  const history = useHistory();

  useEffect(() => {
    DestinationView();
  }, []);
  const handleInputChange = (e) => {
    setDetails({
      ...Details,
      s_place: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    // console.log(s_image, "filessss");

    setDetails({
      ...Details,
      s_image: e.target.files[0],
    });
  };
  const handleInputDescrption = (e) => {
    setDetails({
      ...Details,
      sd_description1: e.target.value,
    });
  };
  const handleInputDescrption2 = (e) => {
    setDetails({
      ...Details,
      sd_description2: e.target.value,
    });
  };
  const DestinationView = () => {
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/view-add",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
      data: {
        s_id: workLocationId,
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          console.log(data.data.list, "listing");

          setFormData(data.data.list[0]);
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append(
      "s_place",
      Details.s_place ? Details.s_place : formData.s_place
    );
    formdata.append(
      "image",
      Details.s_image ? Details.s_image : formData.s_image
    );
    formdata.append("s_id", workLocationId);
    formdata.append(
      "des1",
      Details.sd_description1
        ? Details.sd_description1
        : formData.sd_description1
    );
    formdata.append(
      "des2",
      Details.sd_description2
        ? Details.sd_description2
        : formData.sd_description2
    );

    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/edit/study/destination",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          toast.success(data?.data?.message);
          history.push("/services/WorkDestination");
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };
  {
    console.log(formData, "formData");
  }
  return (
    <>
      {/* <ToastContainer autoClose={3000} theme={"light"} /> */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Edit Work Location
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label={formData.s_place}
                      name="place"
                      value={Details.s_place}
                      onChange={handleInputChange}
                      fullWidth
                      // required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                      Upload Image
                      <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        // required
                      />
                    </Button>
                    {formData.s_image && (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {Details?.s_image?.name
                          ? Details?.s_image?.name
                          : formData.s_image}
                      </Typography>
                    )}
                  </Grid>
                  {/* Mini Description Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={formData.sd_description1}
                      name="sd_description1"
                      placeholder={formData.sd_description1}
                      value={Details.sd_description1}
                      onChange={handleInputDescrption}
                      fullWidth
                      // required
                    />
                  </Grid>

                  {/* Large Description Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={formData.sd_description2}
                      name="sd_description2"
                      placeholder={formData.sd_description2}
                      value={Details.sd_description2}
                      onChange={handleInputDescrption2}
                      multiline
                      rows={4}
                      fullWidth
                      // required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginTop: "15px" }}
                    >
                      Edit Work Location
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EditPages;
