import React, { useEffect, useState } from "react";
import {
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "./LoadingSpinner";

const WorkDestination = ({ history }) => {
  const [open, setOpen] = useState(false);
  const [workLocations, setWorkLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    DestinationList();
  }, []);

  const DestinationList = () => {
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/list/study/destination",
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Basic YnJva2VyOmJyb2tlcl8xMjM=",
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          setWorkLocations(data.data.data);
          setLoading(false);
        } else {
          toast.warning(data?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };
  const handleClickOpen = (location) => {
    console.log(location, "locations");

    setSelectedLocation(location.s_id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/delete/study/destination",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
      data: {
        s_id: selectedLocation,
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          console.log("working", data.data.data);
          setOpen(false);
          DestinationList();
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("no use ::", error);
      });
  };

  let baseUrl = "https://lunarsenterprises.com:6004/";
  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            minHeight: "100vh",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              marginBottom: "20px",
              backgroundColor: "#6c63ff",
              color: "#fff",
            }}
            onClick={() => history.push("/services/Addpages")}
          >
            + Add Work Location
          </Button>

          <Grid container spacing={4}>
            {workLocations.map((location, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card sx={{ position: "relative" }}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={baseUrl + location.s_image}
                    alt={location.s_place}
                  />

                  <CardContent
                    sx={{ textAlign: "center", backgroundColor: "#f0f0f0" }}
                  >
                    <h3>{location.s_place}</h3>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        backgroundColor: "#6c63ff",
                        color: "#fff",
                        marginTop: "10px",
                        "&:hover": {
                          backgroundColor: "#5a54d8",
                        },
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/services/EditPages",
                          state: { workLocationId: location.s_id },
                        })
                      }
                    >
                      Edit Work Location
                    </Button>
                    <IconButton
                      onClick={() => handleClickOpen(location)}
                      sx={{
                        // position: "absolute",
                        top: 4,
                        left: 12,
                        backgroundColor: "#f44336",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#d32f2f",
                        },
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              {"Are you sure you want to delete this location?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deleting {selectedLocation?.s_place} will remove it permanently.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDelete} autoFocus color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default WorkDestination;
