import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "../components/styles/MainDashboard.css";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import backhand from "../assets/5907.jpg";
import ActiveUser from "../assets/ActiveUser.jpg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const userData = [
  { country: "Indonesia", users: 2320, percentage: "42.18%", flag: "🇮🇩" },
  { country: "USA", users: 240, percentage: "4.36%", flag: "🇺🇸" },
  { country: "Australia", users: 119, percentage: "2.16%", flag: "🇦🇺" },
  { country: "Russia", users: 1081, percentage: "19.65%", flag: "🇷🇺" },
  { country: "China", users: 1100, percentage: "20%", flag: "🇨🇳" },
  { country: "Brasil", users: 640, percentage: "11.63%", flag: "🇧🇷" },
];

const markers = [
  { coordinates: [106.84513, -6.20876], name: "Indonesia" },
  { coordinates: [-95.7129, 37.0902], name: "USA" },
  { coordinates: [133.7751, -25.2744], name: "Australia" },
  { coordinates: [105.3188, 61.524], name: "Russia" },
  { coordinates: [104.1954, 35.8617], name: "China" },
  { coordinates: [-51.9253, -14.235], name: "Brazil" },
];

const Dashboard = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//rf.revolvermaps.com/0/0/8.js?i=5wm*****1mh&m=0&c=ff0000&cr1=ffffff&f=arial&l=33";
    script.async = true;
    const mapContainer = document.getElementById("mapContainer");
    if (mapContainer) {
      mapContainer.appendChild(script);
    }
    return () => {
      if (mapContainer && mapContainer.contains(script)) {
        mapContainer.removeChild(script);
      }
    };
  }, []);
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      // {
      //   label: "Subscribers",
      //   data: [100, 200, 150, 300, 200, 500, 400, 600, 700, 800, 900, 1000],
      //   backgroundColor: "rgba(255, 99, 132, 0.2)",
      //   borderColor: "rgba(255, 99, 132, 1)",
      //   borderWidth: 1,
      // },
      {
        label: "New Visitors",
        data: [50, 100, 75, 150, 100, 250, 200, 300, 350, 400, 450, 500],
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Active Users",
        data: [150, 300, 225, 450, 300, 750, 600, 900, 1050, 1200, 1350, 1500],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "User Statistics",
      },
    },
  };
  const geoUrl = "/features.json";
  return (
    <div className="dashboard">
      {/* Statistics Cards */}
      <div className="stats-cards">
        <div className="card">
          <div>
            <img src={backhand} style={{ height: "50px", width: "50px" }} />
          </div>
          <h3>Visitors</h3>
          <p>1,294</p>
        </div>
        <div className="card">
          <img src={ActiveUser} style={{ height: "50px", width: "50px" }} />
          <h3>Active</h3>
          <p>1,303</p>
        </div>
        {/* <div className="card">
          <h3>Sales</h3>
          <p>$1,345</p>
        </div>
        <div className="card">
          <h3>Order</h3>
          <p>576</p>
        </div> */}
      </div>

      <div className="chart-container">
        <Line data={data} options={options} />
      </div>
      <div className="geolocation-container">
        <div className="user-table">
          <h3>Users Geolocation</h3>
          <p>Map of the distribution of users around the world</p>
          <table>
            <thead>
              <tr>
                <th>Country</th>
                <th>Users</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>
                    {data.flag} {data.country}
                  </td>
                  <td>{data.users}</td>
                  <td>{data.percentage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="map-container">
          <ComposableMap projection="geoMercator">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: { fill: "#ECEFF1", outline: "none" },
                      hover: { fill: "#CFD8DC", outline: "none" },
                      pressed: { fill: "#607D8B", outline: "none" },
                    }}
                  />
                ))
              }
            </Geographies>

            {markers.map(({ name, coordinates }) => (
              <Marker key={name} coordinates={coordinates}>
                <circle r={6} fill="#F53" stroke="#fff" strokeWidth={2} />
              </Marker>
            ))}
          </ComposableMap>
        </div>
      </div>
      {/* <div id="mapContainer"></div> */}
    </div>
  );
};

export default Dashboard;
