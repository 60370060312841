import React, { useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const AddPages = () => {
  const [formData, setFormData] = useState({
    place: "",
    image: null,
    des1: "",
    des2: "",
  });
  const history = useHistory();

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("place", formData.place);
    formdata.append("image", formData.image);
    formdata.append("des1", formData.des1);
    formdata.append("des2", formData.des2);

    axios({
      url: "https://lunarsenterprises.com:6004/workabroad/add/study/destination",
      method: "POST",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
      },
    })
      .then((data) => {
        if (data?.data?.result === true) {
          toast.success(data?.data?.message);
          history.push("/services/WorkDestination");
        } else {
          toast.warning(data?.data?.message);
        }
      })
      .catch((error) => {
        console.log("Error occurred:", error);
      });
  };

  return (
    <>
      {/* <ToastContainer autoClose={3000} theme={"light"} /> */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div" gutterBottom>
                Add Location
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Place"
                      name="place"
                      value={formData.place}
                      onChange={handleInputChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="outlined" component="label" fullWidth>
                      Upload Image
                      <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        required
                      />
                    </Button>
                    {formData.image && (
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {formData.image.name}
                      </Typography>
                    )}
                  </Grid>

                  {/* Mini Description Field */}
                  <Grid item xs={12}>
                    <TextField
                      label="Mini Description"
                      name="des1"
                      value={formData.des1}
                      onChange={handleInputChange}
                      fullWidth
                      required
                    />
                  </Grid>

                  {/* Large Description Field */}
                  <Grid item xs={12}>
                    <TextField
                      label="Large Description"
                      name="des2"
                      value={formData.des2}
                      onChange={handleInputChange}
                      multiline
                      rows={4}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginTop: "15px" }}
                    >
                      Add Work Location
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPages;
