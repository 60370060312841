import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import DashboardPage from "./pages/DashboardPage";
import Enquiry from "./components/Enquiry";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/enquiry" component={Enquiry} />
        <Route path="/services" component={Dashboard} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
};

export default App;
